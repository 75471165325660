import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authCode: '',
    authUser: {
      initials: "",
      fullName: "",
      email: "",
      id_profile_user: "",
      id_unity_user: "",
      unity_name: "",
    },
    successMessage: '',
    errorMessage: '',
    stepperPatient: true,
    stepperVisitors: false,
    aghusePatient: {},
    viewPatient: false,
    personTypeCount: {
      qtyVisitor: '',
      qtyEscort: '',
      save: true,
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
