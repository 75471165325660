import axios from 'axios'
import config from './config/config'
import store from './store/index'

const instance = axios.create({})

// Declarando rota default
instance.defaults.baseURL = config.apiURL

// Interceptando requisição
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if (token !== undefined) {
        config.headers.common['Authorization'] = `Bearer ${token}`
    }
    return config
});

// Interceptando resposta
instance.interceptors.response.use(config => {
    if (config.headers.authorization !== undefined) {
        localStorage.setItem('token', config.headers.authorization);
    }
    return config
}, (error) => {

    if (error.response.data.detailsDeparture) {
        throw error.response.data;
    }
    store.state.errorMessage = error.response.data.details
    if (error.response.status == 401) {
        window.location.href = '/'
    }
    if (error.response.status > 401 || error.response.status == 400) {
        throw error.response.data.details;
    }
});

export default instance