import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../axios'
import store from '../store/index'

Vue.use(VueRouter)

async function verificationRouter(to, from, next) {
  await axios.get(`/auth/me`).then((response) => {    
    store.state.userName = response.data.name_user;
    store.state.authCode = response.status;
    store.state.authUser.id_profile_user = response.data.id_profile_user;
    store.state.authUser.id_unity_user = response.data.unity.id;
    store.state.authUser.unity_name = response.data.unity.unity_name;
  })

  if (from.path == '/') {
      store.state.stepperPatient = true;
      store.state.stepperVisitors = false;
      store.state.authUser.fullName = '';
  }
  if (to.path == '/users' && store.state.authUser.id_profile_user != 1) {
    next('/dashboard')
  } 

  if (store.state.authCode == 200) {
    next()
  }

  else {
    next('/')
  }
}

const routes = [

  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/PesquisarMovi',
    beforeEnter: verificationRouter,
    component: () => import('../views/PesquisarMovi.vue'),
    children: [
      {
        path: '',
        name: "Pesquisar Movimentação de Visitantes e Acompanhantes",
        component: () => import('../components/movivisitaacomp/PesquisarMovi-comp.vue'),
      }
    ]
  },

  {
    path: '/VisualizarMovi',
    beforeEnter: verificationRouter,
    component: () => import('../views/VisualizarMovi.vue'),
    children: [
      {
        path: '',
        name: "Visualizar Movimentação de Visitantes e Acompanhantes",
        component: () => import('../components/movivisitaacomp/VisualizarMovi-comp.vue'),
      }
    ]
  },

  {
    path: '/PacieAmbu',
    beforeEnter: verificationRouter,
    component: () => import('../views/PacieAmbu.vue'),
    children: [
      {
        path: '',
        name: "Pesquisar Pacientes",
        component: () => import('../components/movimentacao/PacieAmbu-comp.vue'),
      }
    ]
  },

  {
    path: '/dashboard',
    beforeEnter: verificationRouter,
    component: () => import('../views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../components/dashboard/Dashboard-comp.vue'),
      }
    ],
  },
  {
    path: '/escala-trabalhada',
    beforeEnter: verificationRouter,
    component: () => import('../views/WorkSchedule.vue'),
    children: [
      {
        path: '',
        name: 'Escala Trabalhada',
        component: () => import('../components/work-schedule/WorkSchedule-comp.vue'),
      }
    ],
  },
  {
    path: '/person',
    beforeEnter: verificationRouter,
    component: () => import('../views/Person.vue'),
    children: [
      {
        path: '',
        name: 'Pesquisar Paciente',
        component: () => import('../components/person/patient/SearchPatient.vue'),
      }
    ],
  },
  {
    path: '/users',
    name: 'users',
    beforeEnter: verificationRouter,
    component: () => import('../views/Usuarios.vue'),
    children: [
      {
        path: '',
        name: 'Lista de Usuários',
        component: () => import('../components/users/Table-comp.vue'),
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
