export default {

    //DESENVOLVIMENTO
    // apiURL: 'http://desenv.siscov.saude.ba.gov.br/api',
    // apiAGHUSE: 'http://desenv.siscov.saude.ba.gov.br/api/hospitalized-patient/search-patient',
    // captchaURL: 'http://desenv.siscov.saude.ba.gov.br/captcha/api/default',

    //HOMOLOGAÇÃO
    // apiURL: 'http://homologa.siscov.saude.ba.gov.br/api',
    // apiAGHUSE: 'http://homologa.siscov.saude.ba.gov.br/api/hospitalized-patient/search-patient',
    // captchaURL: 'http://homologa.siscov.saude.ba.gov.br/captcha/api/default',

    //PRODUÇÃO
    apiURL: 'https://siscov.saude.ba.gov.br/api',
    apiAGHUSE: 'https://siscov.saude.ba.gov.br/api/hospitalized-patient/search-patient',
    captchaURL: 'https://siscov.saude.ba.gov.br/captcha/api/default',

    //LOCALHOST
    // apiURL: 'http://localhost:8000/api',
    // apiAGHUSE: 'http://localhost:8000/api/hospitalized-patient/search-patient',
    // captchaURL: 'http://localhost:8000/captcha/api/default',

    //apiURL: 'http://10.17.44.172:8003/api',    
    //apiAGHUSE: 'http://desenv.servicoaghuse.saude.ba.gov.br/api/hospitalized-patient/search-patient',
    //captchaURL: 'http://10.17.44.172:8003/captcha/api/default',


}